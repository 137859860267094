import styled, { css } from 'styled-components';
import { theme } from '../../../styling/theme';
import { IconButtonStyleProps } from './IconButton.types';

export const IconButtonStyle = styled.button<IconButtonStyleProps>`
  all: unset;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: transparent;

  width: ${props => (props.isMobile ? theme(props).SIZE.MIN_TARGET : '36px')};
  ${props =>
    props.isInlineText
      ? css`
          margin-bottom: 6px;
        `
      : css`
          height: ${props.isMobile ? theme(props).SIZE.MIN_TARGET : '36px'};
        `}
  color: ${props =>
    props.color
      ? props.color
      : props.variant === 'primary'
        ? theme(props).COLOUR.PRIMARY
        : theme(props).COLOUR.BACKGROUND_LIGHT};
  ${props =>
    props.variant === 'primary' &&
    css`
      border: none;
    `};

  &:disabled {
    color: ${props =>
      props.variant === 'primary'
        ? theme(props).COLOUR.BACKGROUND_LIGHT
        : 'white'};
    pointer-events: none;
  }
`;

export const IconButtonCircle = styled(IconButtonStyle)`
  height: ${props => theme(props).SIZE.LARGE};
  width: auto;
  aspect-ratio: 1;
  border: 1px solid ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
  border-radius: 50%;
  color: ${props => theme(props).COLOUR.BACKGROUND_DARK};
  outline: none;
  ${props => theme(props).TYPOGRAPHY.MEDIUM_1_alt};
  transition: ${props => theme(props).EFFECTS.TRANSITION_QUICK};
  transition-property: color, background-color;
`;
