import { DispatchFn } from '../state';
import client, { Microservice } from '../../utils/http.utils';
import {
  TransactionApiModel,
  CurrencyBalance,
  MinWithdrawAmounts,
  WithdrawalApiModel,
  AutomaticWithdrawalSettingsApiModel,
  AutomaticWithdrawalUpdateApiModel,
  RawReadTransactionsApiModel,
  Balances,
} from './store';
import { apiError } from '../../types/error.types';
import { captureException } from '../../utils/sentry.utils';
import { Currency } from '../../utils/localisation_currencies.utils';
import { queryBuilder } from '../../contexts/common/utils';

export enum WalletActions {
  requestGetStatisticsAttempt = 'REQUEST_GET_STATISTICS_ATTEMPT',
  requestGetStatisticsSuccess = 'REQUEST_GET_STATISTICS_SUCCESS',
  requestGetStatisticsFailure = 'REQUEST_GET_STATISTICS_FAILURE',

  requestGetBalancesAttempt = 'REQUEST_GET_BALANCES_ATTEMPT',
  requestGetBalancesSuccess = 'REQUEST_GET_BALANCES_SUCCESS',
  requestGetBalancesFailure = 'REQUEST_GET_BALANCES_FAILURE',

  requestGetTransactionsAttempt = 'REQUEST_GET_TRANSACTIONS_ATTEMPT',
  requestGetTransactionsSuccess = 'REQUEST_GET_TRANSACTIONS_SUCCESS',
  requestGetTransactionsFailure = 'REQUEST_GET_TRANSACTIONS_FAILURE',

  requestPostWithdrawalAttempt = 'REQUEST_POST_WITHDRAWAL_ATTEMPT',
  requestPostWithdrawalSuccess = 'REQUEST_POST_WITHDRAWAL_SUCCESS',
  requestPostWithdrawalFailure = 'REQUEST_POST_WITHDRAWAL_FAILURE',
  requestPostWithdrawalValidationFailure = 'REQUEST_POST_WITHDRAWAL_VALIDATION_FAILURE',
  clearPostWithdrawalErrors = 'CLEAR_POST_WITHDRAWAL_ERRORS',

  requestGetMinimumWithdrawalAmountsAttempt = 'REQUEST_GET_MINIMUM_WITHDRAWAL_AMOUNTS_ATTEMPT',
  requestGetMinimumWithdrawalAmountsSuccess = 'REQUEST_GET_MINIMUM_WITHDRAWAL_AMOUNTS_SUCCESS',
  requestGetMinimumWithdrawalAmountsFailure = 'REQUEST_GET_MINIMUM_WITHDRAWAL_AMOUNTS_FAILURE',

  setCurrentBankAccountStatus = 'SET_CURRENT_BANK_ACCOUNT_STATUS',
  clearCurrentBankAccountStatus = 'CLEAR_CURRENT_BANK_ACCOUNT_STATUS',

  requestGetAutomaticWithdrawalSettingsAttempt = 'GET_AUTOMATIC_WITHDRAWAL_SETTINGS_ATTEMPT',
  requestGetAutomaticWithdrawalSettingsSuccess = 'GET_AUTOMATIC_WITHDRAWAL_SETTINGS_SUCCESS',
  requestGetAutomaticWithdrawalSettingsFailure = 'GET_AUTOMATIC_WITHDRAWAL_SETTINGS_FAILURE',

  requestUpdateAutomaticWithdrawalSettingsAttempt = 'UPDATE_AUTOMATIC_WITHDRAWAL_SETTINGS_ATTEMPT',
  requestUpdateAutomaticWithdrawalSettingsSuccess = 'UPDATE_AUTOMATIC_WITHDRAWAL_SETTINGS_SUCCESS',
  requestUpdateAutomaticWithdrawalSettingsFailure = 'UPDATE_AUTOMATIC_WITHDRAWAL_SETTINGS_FAILURE',

  requestGetAgencyBalancesAttempt = 'REQUEST_GET_AGENCY_BALANCES_ATTEMPT',
  requestGetAgencyBalancesSuccess = 'REQUEST_GET_AGENCY_BALANCES_SUCCESS',
  requestGetAgencyBalancesFailure = 'REQUEST_GET_AGENCY_BALANCES_FAILURE',

  requestGetAgentTransactionsAttempt = 'REQUEST_GET_AGENT_TRANSACTIONS_ATTEMPT',
  requestGetAgentTransactionsSuccess = 'REQUEST_GET_AGENT_TRANSACTIONS_SUCCESS',
  requestGetAgentTransactionsFailure = 'REQUEST_GET_AGENT_TRANSACTIONS_FAILURE',

  requestGetAgencyCurrenciesAttempt = 'REQUEST_GET_AGENCY_CURRENCIES_ATTEMPT',
  requestGetAgencyCurrenciesSuccess = 'REQUEST_GET_AGENCY_CURRENCIES_SUCCESS',
  requestGetAgencyCurrenciesFailure = 'REQUEST_GET_AGENCY_CURRENCIES_FAILURE',

  requestGetWalletDataAttempt = 'REQUEST_GET_WALLET_DATA_ATTEMPT',
  requestGetWalletDataSuccess = 'REQUEST_GET_WALLET_DATA_SUCCESS',
  requestGetWalletDataFailure = 'REQUEST_GET_WALLET_DATA_FAILURE',

  requestGetFirstTransactionDateAttempt = 'REQUEST_GET_FIRST_TRANSACTION_DATE_ATTEMPT',
  requestGetFirstTransactionDateSuccess = 'REQUEST_GET_FIRST_TRANSACTION_DATE_SUCCESS',
  requestGetFirstTransactionDateFailure = 'REQUEST_GET_FIRST_TRANSACTION_DATE_FAILURE',
}

export const RequestGetWalletStatisticsAsync = async (
  dispatch: DispatchFn,
  username: string
) => {
  dispatch({ type: WalletActions.requestGetStatisticsAttempt });
  try {
    const json = await client.apiRequest(
      `/users/${username}/statistics`,
      'GET'
    );
    return dispatch({
      type: WalletActions.requestGetStatisticsSuccess,
      payload: json,
    });
  } catch (e) {
    captureException(e);
    return dispatch({ type: WalletActions.requestGetStatisticsFailure });
  }
};

export const RequestGetInfluencerBalances = async (
  dispatch: DispatchFn,
  influencerId: string
) => {
  dispatch({ type: WalletActions.requestGetBalancesAttempt });

  try {
    const json = await client.request<Balances>(
      Microservice.TRANSACTION,
      `/balance/${influencerId}`,
      'GET'
    );

    dispatch({
      type: WalletActions.requestGetBalancesSuccess,
      payload: json,
    });
  } catch (e: any) {
    captureException(e);
    const payload =
      e.status === 503
        ? 'APP.WALLET.SERVER_UNAVAILABLE'
        : 'APP.WALLET.GENERIC_ERROR';
    dispatch({
      type: WalletActions.requestGetBalancesFailure,
      payload: payload,
    });
  }
};

export const RequestGetWalletTransactionsAsync = async (
  dispatch: DispatchFn,
  pageNumber: number,
  currency: string
) => {
  dispatch({ type: WalletActions.requestGetTransactionsAttempt });
  try {
    const query = `?pageNo=${pageNumber}&currency=${currency}`;
    const url = `/transaction/influencer${query}`;
    const json = await client.request(Microservice.TRANSACTION, url, 'GET');

    dispatch({
      type: WalletActions.requestGetTransactionsSuccess,
      payload: json,
    });
  } catch (e: any) {
    captureException(e);
    const payload =
      e.status === 503
        ? 'APP.WALLET.SERVER_UNAVAILABLE'
        : 'APP.WALLET.GENERIC_ERROR';
    dispatch({
      type: WalletActions.requestGetTransactionsFailure,
      payload: payload,
    });
  }
};

export const ClearPostWithdrawalErrors = (dispatch: DispatchFn) => {
  dispatch({ type: WalletActions.clearPostWithdrawalErrors });
};

export const RequestPostWalletWithdrawalAsync = async (
  dispatch: DispatchFn,
  withdrawal: WithdrawalApiModel
): Promise<void> => {
  try {
    dispatch({ type: WalletActions.requestPostWithdrawalAttempt });

    await client.request<TransactionApiModel, WithdrawalApiModel>(
      Microservice.TRANSACTION,
      '/transaction/withdraw',
      'POST',
      withdrawal
    );

    dispatch({
      type: WalletActions.requestPostWithdrawalSuccess,
    });
  } catch (e: any) {
    captureException(e);
    if (e.status == 406) {
      dispatch({
        type: WalletActions.requestPostWithdrawalValidationFailure,
        payload: true,
      });
    } else {
      dispatch({
        type: WalletActions.requestPostWithdrawalFailure,
        payload: 'An error occurred while requesting balance withdrawal.',
      });
    }

    throw apiError(e.status, e.message);
  }
};

export const RequestGetMinWithdrawalAmountsAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  dispatch({ type: WalletActions.requestGetMinimumWithdrawalAmountsAttempt });
  try {
    const response = await client.request<MinWithdrawAmounts, null>(
      Microservice.TRANSACTION,
      '/transaction/static/minimum-withdrawal-amounts',
      'GET'
    );
    dispatch({
      type: WalletActions.requestGetMinimumWithdrawalAmountsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: WalletActions.requestGetMinimumWithdrawalAmountsFailure,
      payload:
        'An error occurred while fetching minimum amounts for withdrawal.',
    });
  }
};

export const SetCurrentBankAccountStatus = (
  dispatch: DispatchFn,
  status: CurrencyBalance
) => {
  dispatch({
    type: WalletActions.setCurrentBankAccountStatus,
    payload: status,
  });
};

export const ClearCurrentBankAccountStatus = (dispatch: DispatchFn) => {
  dispatch({
    type: WalletActions.clearCurrentBankAccountStatus,
  });
};

export const RequestGetAutomaticWithdrawalSettingsAsync = async (
  dispatch: DispatchFn,
  influencerId: string
): Promise<void> => {
  dispatch({
    type: WalletActions.requestGetAutomaticWithdrawalSettingsAttempt,
  });
  try {
    const response = await client.request<
      AutomaticWithdrawalSettingsApiModel[],
      null
    >(
      Microservice.PAYMENT,
      `/automatic_withdrawal/influencer/${influencerId}`,
      'GET'
    );

    dispatch({
      type: WalletActions.requestGetAutomaticWithdrawalSettingsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: WalletActions.requestGetAutomaticWithdrawalSettingsFailure,
      payload:
        'An error occurred while fetching Automatic Withdrawal Settings.',
    });
  }
};

export const RequestUpdateAutomaticWithdrawalSettingsAsync = async (
  dispatch: DispatchFn,
  influencerId: string,
  currency: string,
  enabled: boolean,
  paymentDetailsId: string | null
): Promise<void> => {
  dispatch({
    type: WalletActions.requestUpdateAutomaticWithdrawalSettingsAttempt,
    payload: {
      currency: currency,
    },
  });
  try {
    await client.request<null, AutomaticWithdrawalUpdateApiModel>(
      Microservice.PAYMENT,
      `/automatic_withdrawal/influencer/${influencerId}/${currency}`,
      'PUT',
      {
        enabled: enabled,
        payment_details_id: paymentDetailsId,
      }
    );

    dispatch({
      type: WalletActions.requestUpdateAutomaticWithdrawalSettingsSuccess,
      payload: {
        currency,
      },
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: WalletActions.requestUpdateAutomaticWithdrawalSettingsFailure,
      payload: {
        currency,
        error:
          'An error occurred while updating Automatic Withdrawal Settings.',
      },
    });
  }
};

export const RequestGetAgencyBalances = async (
  dispatch: DispatchFn,
  agencyId: string
) => {
  dispatch({ type: WalletActions.requestGetAgencyBalancesAttempt });

  try {
    const json = await client.request<Balances>(
      Microservice.TRANSACTION,
      `/balance/agency/${agencyId}`,
      'GET'
    );

    dispatch({
      type: WalletActions.requestGetAgencyBalancesSuccess,
      payload: json,
    });
  } catch (e: any) {
    captureException(e);
    const payload =
      e.status === 503
        ? 'APP.WALLET.SERVER_UNAVAILABLE'
        : 'APP.WALLET.GENERIC_ERROR';
    dispatch({
      type: WalletActions.requestGetAgencyBalancesFailure,
      payload: payload,
    });
  }
};

export const RequestGetAgentWalletTransactionsAsync = async (
  dispatch: DispatchFn,
  pageNumber: number,
  currency: string,
  influencerSearch: string
) => {
  dispatch({ type: WalletActions.requestGetAgentTransactionsAttempt });
  try {
    const query = `?pageNo=${pageNumber}&currency=${currency}`;
    const nameSearch = `&name=${influencerSearch}`;
    const url =
      influencerSearch.trim() == ''
        ? `/transaction/agency${query}`
        : `/transaction/agency${query}${nameSearch}`;
    const response = await client.request<RawReadTransactionsApiModel>(
      Microservice.TRANSACTION,
      url,
      'GET'
    );

    dispatch({
      type: WalletActions.requestGetAgentTransactionsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: WalletActions.requestGetAgentTransactionsFailure,
      payload: 'An Error occurred while fetching all transactions.',
    });
  }
};

export const RequestGetAgencyCurrenciesAsync = async (
  dispatch: DispatchFn,
  agencyId: string
) => {
  dispatch({ type: WalletActions.requestGetAgencyCurrenciesAttempt });
  try {
    const response: (keyof typeof Currency)[] = await client.request(
      Microservice.AGENCY,
      `/agency/${agencyId}/currency`,
      'GET'
    );
    dispatch({
      type: WalletActions.requestGetAgencyCurrenciesSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: WalletActions.requestGetAgencyCurrenciesFailure,
      payload: 'An error occurred while fetching currencies.',
    });
  }
};

export const RequestGetWalletDataAsync = async (
  dispatch: DispatchFn,
  agencyId: string,
  date: string,
  currency?: string,
  transactionType?: string
) => {
  dispatch({ type: WalletActions.requestGetWalletDataAttempt });
  try {
    const queries = queryBuilder({
      currency: currency,
      transaction_type: transactionType,
    });
    const response = await client.request(
      Microservice.TRANSACTION,
      `/transaction/agency/${agencyId}/wallet/csv-export/${date}${queries}`,
      'GET'
    );
    dispatch({
      type: WalletActions.requestGetWalletDataSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: WalletActions.requestGetWalletDataFailure,
      payload: 'An error occurred while fetching wallet data.',
    });
  }
};

export const RequestGetFirstTransactionDateAsync = async (
  dispatch: DispatchFn,
  agencyId: string
) => {
  dispatch({ type: WalletActions.requestGetFirstTransactionDateAttempt });
  try {
    const response = await client.request<string>(
      Microservice.TRANSACTION,
      `/transaction/user/${agencyId}/first-transaction-date`,
      'GET'
    );
    dispatch({
      type: WalletActions.requestGetFirstTransactionDateSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: WalletActions.requestGetFirstTransactionDateFailure,
      payload: 'An error occurred while fetching first transaction date.',
    });
  }
};
